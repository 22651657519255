window.ranger = window.ranger || {};

/**
 * Handles the User Profile UI.
 */
ranger.GroupsPage = class {

  /**
   * Initializes the user's profile UI.
   * @constructor
   */
  constructor() {
    // Firebase SDK.
    this.database = firebase.database();
    this.auth = firebase.auth();
    window.groupId;
    window.groupName;

    $(document).ready(() => {
      // DOM Elements.
        this.createGroupBtn = $('#createGroup');
        this.createGroupInput = $('#createGroupInp');
        this.addMemberInput = $('#addMemberInp');
        this.searchResults = $('#groupSearchResults');

      //Bind Actions
      this.createGroupBtn.click(() => this.createGroup());
      this.addMemberInput.keyup(() => this.searchMembers());
      this.addMemberInput.focus(() => this.searchMembers());
      this.addMemberInput.click(() => this.searchMembers());
        
      var startListeners = function() {
          firebase.database().ref(`groups`).off();
          firebase.database().ref(`groups/${window.groupId}/members`).on('child_added', function(data) {
             ranger.groupsPage.groupMembersAddHtmlFunction(data.key, data.val()); 
          });
          
          firebase.database().ref(`groups/${window.groupId}/members`).on('child_removed', function(data) {
             $('#' + data.key).remove();
          });
      }

      $('#groupsList').on('click', 'a', function() {
          $('#members').show();
          $('#memberList').empty();
          
          window.groupId = $(this).data('key');
          window.groupName = $(this).data('name');
          startListeners();
         /*ranger.firebase.loadGroupUsers($(this).data('key')).then(function(members) {
            members.forEach(function(member) {
               ranger.groupsPage.groupMembersAddHtmlFunction(member.key, member.val());
            });
         });*/
      });

      $('#groupSearchResults').on('click', '.selectMember', function() {
         ranger.firebase.addGroupMember(window.groupId, window.groupName, $(this).data('id'), $(this).data('name'));

          $('#addMemberInp').val('');
      });

      $('#memberList').on('click', '.deleteMember', function() {
        ranger.firebase.deleteGroupMember(window.groupId, $(this).data('key')).then(function(error) {
            if(error) {
                console.log(error);
            }                                                             
        });
      });
    });
  }
    groupsAddHtmlFunction(key, name) {
        /*var displayGroup = `<li class="mdl-list__item"><a data-key="${key}" data-name="${name}" class="fp-usernamelink mdl-button mdl-js-button">
            <div class="fp-avatar"><i class="material-icons">group</i></div>
            <div class="fp-username mdl-color-text--black">${name}</div>
          </a></li>`;*/
        
        var displayGroup = `<li class="mdl-list__item"><a data-key="${key}" data-name="${name}" class="mdl-list__item-primary-content"><i class="material-icons mdl-list__item-icon">group</i>${name}</a></li>`;

        $('#groupsList').append(displayGroup);
    }

    groupMembersAddHtmlFunction(key, name) {

        /*var displayMember = `<a class="fp-usernamelink mdl-button mdl-js-button">
            <div class="fp-avatar"><i class="material-icons">person</i></div>
            <div data-key="${key}" class="fp-username mdl-color-text--black">${name}</div>
          </a>`;*/
        var displayMember = `<div id="${key}" class="mdl-list__item">
            <span class="mdl-list__item-primary-content">
            <i class="material-icons mdl-list__item-avatar">person</i>
            <span>${name}</span>
            </span>
            <a data-key="${key}" class="mdl-list__item-secondary-action deleteMember"><i class="material-icons">delete</i></a>
            </div>`;

        $('#memberList').append(displayMember);
    }

    loadGroups() {
        $('#groupsList').empty();
        ranger.firebase.loadGroups();
    }

    createGroup() {
        const groupName = this.createGroupInput.val();
        this.createGroupInput.val('');
        var groupKey;

        if (groupName !='') {
            groupKey = ranger.firebase.createGroup(groupName);
        } else {
            var data = {
              message: 'Please Enter A Group Name',
              timeout: 4000
            };
            this.toast[0].MaterialSnackbar.showSnackbar(data);
        }
    }

    leaveGroup() {

    }

    deleteGroup() {

    }

    joinGroup() {

    }

    sendInvite() {

    }
    
    displayGroupFeed() {
        
    }

  searchMembers() {
    const searchString = this.addMemberInput.val().toLowerCase().trim();
    if (searchString.length >= ranger.Search.MIN_CHARACTERS) {
      ranger.firebase.searchFollowers(searchString, ranger.Search.NB_RESULTS_LIMIT).then(
          results => {
            this.searchResults.empty();
            const peopleIds = Object.keys(results);
            if (peopleIds.length > 0) {
              this.searchResults.fadeIn();
              $('html').click(() => {
                $('html').unbind('click');
                this.searchResults.fadeOut();
              });
              peopleIds.forEach(peopleId => {
                const profile = results[peopleId];
                this.searchResults.append(
                    ranger.Search.createMemberSearchResultHtml(peopleId, profile));
              });
            } else {
              this.searchResults.fadeOut();
            }
          });
    } else {
      this.searchResults.empty();
      this.searchResults.fadeOut();
    }
  }

};

ranger.groupsPage = new ranger.GroupsPage();
